import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  spawn,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import loginMethod from 'src/rest/requests/auth/loginMethod'
import { User } from 'src/rest/types/User'

import { actions, selectors } from '..'
import { getAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'
import login from '../../rest/requests/auth/login'
import me from '../../rest/requests/auth/me'
import ApiSagas from '../api/sagas'
import Router, { routes } from '../../routes/Router'
import onboard from '../../rest/requests/auth/onboard'
import BugsnagHelpers from '../../helpers/BugsnagHelpers'
import register from '../../rest/requests/auth/register'
import emailLogin from '../../rest/requests/auth/emailLogin'
import forgottenPassword from '../../rest/requests/auth/forgottenPassword'
import resetPassword from '../../rest/requests/auth/resetPassword'
import updateProfile from '../../rest/requests/auth/updateProfile'
import {
  getPubNubInstance,
  getUuid,
  resetPubNubInstance,
} from '../../helpers/PubNubHelpers'

export default class AuthSagas {
  static *onInit() {
    const cookie = getAuthCookie()
    console.log('AUTH : init with cookie', cookie)
    if (cookie) {
      yield put(actions.auth.setImpersonate(cookie?.impersonate ?? false))
      yield put(actions.auth.setToken(cookie?.token))
    }

    yield spawn(AuthSagas.onMe, true)
  }

  static *onMe(firstLoad?: boolean) {
    const token: SagaReturnType<typeof selectors.auth.token> = yield select(
      selectors.auth.token
    )

    if (token) {
      yield put(actions.auth.meIsLoading(true))

      const response = yield* ApiSagas.rest(me())

      if (response.data && response?.data?.id) {
        yield put(actions.auth.setUser(response.data))
        yield call(getPubNubInstance, getUuid(response.data))
        yield put(actions.chat.init())
        yield put(actions.chat.refreshUnreadCount())
        yield put(actions.reservations.getAllUserReservationsRequest())
        if (firstLoad === true) {
          yield spawn(AuthSagas.shouldOpenValidateEmailModal, response.data)
        }
        if (
          !response.data.onboarded &&
          response.data.role.name === 'Full access'
        ) {
          Router.push(Router.getRouteUrl(routes.page, { slug: 'onboarding' }))
        }
        setAuthCookie({ token, impersonate: false })
      } else {
        yield put(actions.auth.resetAuth())
      }

      yield put(actions.auth.meIsLoading(false))
    }
  }

  static *shouldOpenValidateEmailModal(user: User) {
    if (user.email.includes('@strapi.io')) {
      yield put(actions.modals.setValidateUserModalIsOpen(true))
    }
  }

  static *onLoginRequest({
    payload,
  }: ReturnType<typeof actions.auth.loginRequest>) {
    const response = yield* ApiSagas.rest(login(payload))

    if (response.data && !response?.data?.error) {
      setAuthCookie({ token: response.data.jwt, impersonate: false })
      yield put(actions.auth.setToken(response.data.jwt))
      yield put(actions.auth.setUser(response.data.user))
      yield put(actions.auth.loginSuccess(response.data))
      // load me to get avatar, reservations, etc
      yield put(actions.auth.me())
    } else {
      BugsnagHelpers?.notify(new Error('Login error'), (event) => {
        event.addMetadata('error', response.errors)
        event.addMetadata('error_payload', payload)
      })

      yield put(actions.auth.loginError(response.data?.error))
    }
  }

  static *onLoginImpersonateRequest({
    payload,
  }: ReturnType<typeof actions.auth.loginImpersonateRequest>) {
    const jwt = payload.jwt
    const decoded = jwtDecode<JwtPayload & { id?: string }>(jwt)
    const expirationDate = decoded?.exp
    const expired = expirationDate ? Date.now() >= expirationDate * 1000 : true

    if (jwt && decoded.id && !expired) {
      setAuthCookie({ token: payload.jwt, impersonate: true })
      yield put(actions.auth.setToken(payload.jwt))
      yield put(actions.auth.loginImpersonateSuccess(true))
      yield put(actions.auth.me())
    } else {
      BugsnagHelpers?.notify(new Error('Login error'), (event) => {
        event.addMetadata('error impersonate', payload)
      })

      yield put(actions.auth.loginImpersonateError(true))
    }
  }

  static *onEmailLoginRequest({
    payload,
  }: ReturnType<typeof actions.auth.emailLoginRequest>) {
    const response = yield* ApiSagas.rest(emailLogin(payload))

    if (response.data && !response?.data?.error) {
      setAuthCookie({ token: response.data.jwt, impersonate: false })
      yield put(actions.auth.setToken(response.data.jwt))
      yield put(actions.auth.setUser(response.data.user))
      yield put(actions.auth.emailLoginSuccess(response.data))
      yield put(actions.auth.me())
    } else {
      BugsnagHelpers?.notify(new Error('Login error'), (event) => {
        event.addMetadata('error', response.errors)
        event.addMetadata('error_payload', payload)
      })

      yield put(actions.auth.emailLoginError(response.data?.error))
    }
  }

  static *onRegisterRequest({
    payload,
  }: ReturnType<typeof actions.auth.registerRequest>) {
    const response = yield* ApiSagas.rest(register(payload))
    if (response.data && !response.data.error) {
      yield put(actions.auth.registerSuccess(response.data))
    } else {
      yield put(actions.auth.registerError([response.data.error]))

      BugsnagHelpers?.notify(new Error('Login error'), (event) => {
        event.addMetadata('Register error', response.errors)
      })
    }
  }

  static *onForgottenPasswordRequest({
    payload,
  }: ReturnType<typeof actions.auth.forgottenPasswordRequest>) {
    const response = yield* ApiSagas.rest(forgottenPassword(payload))
    if (response.data && !response.data.error) {
      yield put(actions.auth.forgottenPasswordSuccess(response.data))
    } else {
      yield put(actions.auth.forgottenPasswordError(response.data.error))

      BugsnagHelpers?.notify(new Error('Forgotten password error'), (event) => {
        event.addMetadata('error', response.errors)
      })
    }
  }

  static *onResetPasswordRequest({
    payload,
  }: ReturnType<typeof actions.auth.resetPasswordRequest>) {
    const response = yield* ApiSagas.rest(resetPassword(payload))
    if (response.data && !response.data.error) {
      yield put(actions.auth.resetPasswordSuccess(response.data))
    } else {
      yield put(actions.auth.resetPasswordError(response.data.error))

      BugsnagHelpers?.notify(new Error('Reset password error'), (event) => {
        event.addMetadata('error', response.errors)
      })
    }
  }

  static *onUpdateProfileRequest({
    payload,
  }: ReturnType<typeof actions.auth.updateProfileRequest>) {
    const response = yield* ApiSagas.rest(updateProfile(payload))
    if (response.data && !response.data.error) {
      const user: User = yield select(selectors.auth.user)
      yield put(actions.auth.updateProfileSuccess(response.data))
      yield put(
        actions.auth.setUser({
          ...user,
          ...response.data,
        })
      )
    } else {
      yield put(actions.auth.updateProfileError(response.data.error))

      BugsnagHelpers?.notify(new Error('Update profile error'), (event) => {
        event.addMetadata('error', response.errors)
      })
    }
  }

  static *onVisitOnboarding() {
    const user: SagaReturnType<typeof selectors.auth.user> = yield select(
      selectors.auth.user
    )
    if (user?.id && !user?.onboarded) {
      yield* ApiSagas.rest(onboard())
      yield put(actions.auth.setUser({ ...user, onboarded: true }))
    }
  }

  static *onLogout() {
    console.log('User logged off')
    yield put(actions.auth.resetAuth())
  }

  static *onResetAuth() {
    setAuthCookie(null)
    yield call(resetPubNubInstance)
  }

  static *onLoginMethodRequest({
    payload,
  }: ReturnType<typeof actions.auth.loginMethodRequest>) {
    const response = yield* ApiSagas.rest(loginMethod(payload))

    if (!response.errors && response.data && !response.data?.data?.error) {
      yield put(actions.auth.loginMethodSuccess(response.data))
    } else {
      yield put(
        actions.auth.loginMethodError(
          response?.errors || response?.data?.data?.error
        )
      )
    }
  }

  static *loop() {
    yield all([
      takeEvery(actions.auth.loginRequest, this.onLoginRequest),
      takeEvery(
        actions.auth.loginImpersonateRequest,
        this.onLoginImpersonateRequest
      ),
      takeEvery(actions.auth.registerRequest, this.onRegisterRequest),
      takeEvery(actions.auth.emailLoginRequest, this.onEmailLoginRequest),
      takeEvery(
        actions.auth.forgottenPasswordRequest,
        this.onForgottenPasswordRequest
      ),
      takeEvery(actions.auth.resetPasswordRequest, this.onResetPasswordRequest),
      takeEvery(actions.auth.updateProfileRequest, this.onUpdateProfileRequest),
      takeLeading(actions.auth.logout, this.onLogout),
      takeLatest(actions.auth.me, this.onMe),
      takeLatest(actions.auth.visitOnboarding, this.onVisitOnboarding),
      takeLatest(actions.auth.resetAuth, this.onResetAuth),
      takeLatest(actions.auth.loginMethodRequest, this.onLoginMethodRequest),
    ])
  }
}
