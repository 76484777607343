import PubNub from 'pubnub'

import { pubNub as pubNubApi } from '../configuration'
import { User } from '../rest/types/User'
import { ImageTransformer } from '../relay/Transformers/ImageTransformer'
import { SImage } from '../rest/types/api/Image'

let instance: PubNub | null = null

export const getPubNubInstance = (userUUID: string): PubNub | null => {
  if (!instance || instance.getUUID() !== userUUID) {
    instance = new PubNub({
      publishKey: pubNubApi.PUBNUB_PUBLISH_KEY, // allow publishing of messages
      subscribeKey: pubNubApi.PUBNUB_SUBSCRIBE_KEY ?? '', // allow subscribing to channel
      uuid: userUUID, // unique for each client
      restore: true, // enable catchup on missed messages
      // authKey: 'authKey', //required if PAM is enabled on the keyset
    })
  }

  return instance
}

export const resetPubNubInstance = () => {
  instance = null
}

export const getUuid = (user: any) => {
  return `${user.id}_${user.firstName.replace(
    /\s/g,
    ''
  )}_${user.lastName.replace(/\s/g, '')}`
}

export const generateChannelID = (
  user1: User,
  user2: User,
  answerId: string
) => {
  const user1ID = parseInt(user1.id, 10)
  const user2ID = parseInt(user2.id, 10)
  const answerID = parseInt(answerId, 10)

  return user1ID > user2ID
    ? `channel_${user2ID}_${answerID}_${user1ID}`
    : `channel_${user1ID}_${answerID}_${user2ID}`
}

export const getTimetoken = (pubnub: PubNub) => pubnub.time()

export const getMembershipToChannel = (
  pubnub: PubNub,
  uuid: string
): Promise<
  PubNub.ManageMembershipsResponse<PubNub.ObjectCustom, PubNub.ObjectCustom>
> =>
  pubnub.objects.getMemberships({
    uuid,
    include: { customFields: true },
  })

export const setMembershipToChannel = async (
  pubnub: any,
  uuid: any,
  channel: any
) => {
  const result = await getTimetoken(pubnub)

  const arg = {
    uuid,
    channels: [
      { id: channel, custom: { lastReadTimetoken: result.timetoken } },
    ],
  }

  pubnub.objects.setMemberships(arg)
  return {
    channel: { id: channel },
    custom: { lastReadTimetoken: result.timetoken },
  }
}

export const hasAlreadyAMembershipToChannel = (
  memberships:
    | PubNub.ChannelMembershipObject<PubNub.ObjectCustom, PubNub.ObjectCustom>[]
    | null,
  channelID: string
): boolean => {
  if (!memberships) {
    return false
  }

  return memberships.some((membership) => membership.channel.id === channelID)
}

export const setUserMetadata = async (pubnub: PubNub, user: User) => {
  const avatar = ImageTransformer({
    attributes: user?.avatar,
  } as SImage)

  pubnub.objects.setUUIDMetadata({
    data: {
      name: `${user.firstName} ${user.lastName}`,
      profileUrl: avatar?.src as string,
    },
  })
}

export const getAllUsersMetadata = (pubnub: PubNub) =>
  pubnub.objects.getAllUUIDMetadata()
