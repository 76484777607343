import dayjs from 'dayjs'
import { BlockCategory } from 'src/forms/AdsForm/types'

import type {
  SClassified,
  SClassifiedAlert,
  SClassifiedAnswer,
  SClassifiedCabinetType,
  SClassifiedCategory,
  SClassifiedEmitter,
  SClassifiedMedia,
  SClassifiedOfferType,
  SClassifiedSecretariat,
  SClassifiedSpecialty,
} from '../types/api/Classified'
import type {
  Classified,
  ClassifiedAlert,
  ClassifiedAnswer,
  ClassifiedCabinetType,
  ClassifiedCategory,
  ClassifiedEmitter,
  ClassifiedMedia,
  ClassifiedOfferType,
  ClassifiedSecretariat,
  ClassifiedSpecialty,
} from '../types/Classified'
import Router from '../../routes/Router'
import routes from '../../routes/definitions'
import { AdsCardProps } from '../../components/AdsCard'
import { AdsCardVariant } from '../../components/AdsCard/styled'
import { SClassifiedView } from '../types/api/ClassifiedView'
import { getCharacters } from '../../helpers/StringHelpers'

import { userTransformer } from './userTransformer'
import { imageTransformer } from './imageTransformer'

export const classifiedTransformer = (data: SClassified): Classified => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return {
    id: data.id,
    ...data.attributes,
    ...(data?.attributes?.emitter?.data && {
      emitter: classifiedEmitterTransformer(data.attributes.emitter.data),
    }),
    ...(data?.attributes?.classifiedCategory?.data && {
      classifiedCategory: {
        ...classifiedCategoryTransformer(
          data?.attributes?.classifiedCategory?.data
        ),
      },
    }),
    ...(data?.attributes?.classified_answers?.data && {
      classified_answers: data?.attributes?.classified_answers?.data.map((a) =>
        ClassifiedAnswerTransformer(a)
      ),
    }),
    ...(data?.attributes?.user?.data && {
      user: userTransformer(data.attributes?.user?.data),
    }),
    ...(data?.attributes?.classified_views?.data && {
      classified_views: data.attributes.classified_views.data.map((v) =>
        classifiedViewTransformer(v)
      ),
      views: String(data.attributes.classified_views.data.length),
    }),
    ...(data?.attributes?.medias?.data && {
      medias: data.attributes.medias?.data.map((m) =>
        classifiedMediaTransformer(m)
      ),
    }),
    ...(data.attributes?.block?.length
      ? {
          block: data.attributes.block.map((block: any) => {
            return {
              ...block,
              offerType: block?.offerType?.data
                ? classifiedOfferTypeTransformer(block.offerType.data)
                : null,
              ...(block?.cabinetType?.data && {
                cabinetType: classifiedCabinetTypeTransformer(
                  block.cabinetType.data
                ),
              }),
              ...(block?.specialities?.data && {
                specialities: block.specialities.data.map((specialty: any) =>
                  classifiedSpecialtyTransformer(specialty)
                ),
              }),
              ...(block?.secretariats?.data && {
                secretariats: block.secretariats.data.map((secretariat: any) =>
                  classifiedSecretariatTransformer(secretariat)
                ),
              }),
              ...(block?.speciality?.data
                ? {
                    speciality: {
                      ...classifiedSpecialtyTransformer(block.speciality.data),
                    },
                  }
                : {}),
            }
          }),
        }
      : { block: [] }),
  }
}

export const classifiedCategoryTransformer = (
  data: SClassifiedCategory
): ClassifiedCategory => {
  return {
    id: data.id,
    ...data.attributes,
    classifieds:
      data?.attributes?.classifieds?.data?.map((item) =>
        classifiedTransformer(item)
      ) ?? [],
  }
}

export const ClassifiedAnswerTransformer = (
  data: SClassifiedAnswer
): ClassifiedAnswer => {
  return {
    id: data.id,
    ...data.attributes,
    ...(data?.attributes?.studentCard?.data && {
      studentCard: imageTransformer(data?.attributes?.studentCard),
    }),
    ...(data?.attributes?.document?.data && {
      document: imageTransformer(data?.attributes?.document),
    }),
    ...(data?.attributes?.classified?.data && {
      classified: classifiedTransformer(data?.attributes?.classified?.data),
    }),
    ...(data?.attributes?.answer_user?.data && {
      answer_user: userTransformer(data?.attributes?.answer_user?.data),
    }),
  }
}

export const classifiedOfferTypeTransformer = (
  data: SClassifiedOfferType
): ClassifiedOfferType => {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export const classifiedSecretariatTransformer = (
  data: SClassifiedSecretariat
): ClassifiedSecretariat => {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export const classifiedSpecialtyTransformer = (
  data: SClassifiedSpecialty
): ClassifiedSpecialty => {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export const classifiedMediaTransformer = (
  data: SClassifiedMedia
): ClassifiedMedia => {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export const classifiedCabinetTypeTransformer = (
  data: SClassifiedCabinetType
): ClassifiedCabinetType => {
  return {
    id: data.id,
    ...data.attributes,
  }
}

export const classifiedEmitterTransformer = (
  data: SClassifiedEmitter
): ClassifiedEmitter => {
  return {
    id: data.id,
    ...data.attributes,
    ...(data.attributes.classifieds?.data && {
      classifieds: data.attributes.classifieds?.data?.map((c) =>
        classifiedTransformer(c)
      ),
    }),
    ...(data.attributes.user?.data && {
      user: userTransformer(data.attributes.user?.data),
    }),
  }
}

export const classifiedViewTransformer = (view: SClassifiedView) => ({
  id: view.id,
  ...(view.attributes.user?.data && {
    user: userTransformer(view.attributes.user.data),
  }),
  ...(view.attributes?.classified?.data && {
    classified: classifiedTransformer(view.attributes.classified.data),
  }),
})

export const classifiedAlertTransformer = (
  data: SClassifiedAlert
): ClassifiedAlert => {
  return {
    id: data.id,
    ...data.attributes,
    ...(data.attributes.categories?.data && {
      categories: data.attributes.categories?.data.map((category) =>
        classifiedCategoryTransformer(category)
      ),
    }),
    ...(data.attributes.user?.data && {
      user: userTransformer(data.attributes.user?.data),
    }),
    ...(data.attributes.specialities?.data && {
      specialities: data.attributes.specialities?.data.map((speciality) =>
        classifiedCategoryTransformer(speciality)
      ),
    }),
  }
}

export const classifiedCardTransformer = ({
  data,
  t,
  colorVariant,
  contentVariant,
}: {
  data: Classified
  t: any
  colorVariant?: AdsCardVariant
  contentVariant?: 'default' | 'withDescription' | 'full' | 'withoutLink'
}): AdsCardProps => {
  return {
    title: data?.title ?? '',
    ...(data.classifiedCategory?.form === BlockCategory.Liberal &&
      data.user?.speciality?.name && {
        title: `${data.title} (${data.user?.speciality.name})`,
      }),
    publicationDate: `${t('block_ads_related_publish_label')} ${dayjs(
      data.updatedAt ?? data.createdAt
    ).format('D MMMM YYYY')}`,
    ...(data?.city &&
      data?.postalCode && {
        localization: t('city_postal_code_formated', {
          city: data?.city,
          postalCode: getCharacters(data?.postalCode ?? '', 0, 5),
        }),
      }),
    offerType: data?.classifiedCategory?.name ?? '',
    ...((contentVariant === 'withDescription' || contentVariant === 'full') && {
      description: data?.summary,
    }),
    ...(contentVariant !== 'withoutLink' && {
      link: {
        href: Router.getRouteUrl(routes.classified, {
          slug: data?.slug,
        }),
        text: t('more_information_button_text'),
      },
    }),
    ...(contentVariant !== 'withDescription' && {
      adViewsNumber: data?.views ?? '0',
      adCommentNumber: '0',
    }),
    variant: colorVariant ?? AdsCardVariant.primary,
  }
}
