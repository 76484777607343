import QueryString from 'qs'

import { getRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { PaginatedResult } from '../../types/PaginatedResult'
import { SUserProfile } from '../../types/api/UserProfile'
import { userProfileTransformer } from '../../transformers/userProfileTransformer'

export type GetProfilesResponse = PaginatedResult<SUserProfile>

const getProfiles = () =>
  getRestRequest({
    url: `${api.REST_API_URL}/user-profiles?${QueryString.stringify(
      {
        populate: {
          image: '*',
        },
      },
      {
        encodeValuesOnly: true,
      }
    )}`,
    transformer: (data: GetProfilesResponse) =>
      data.data.map((profile) => userProfileTransformer(profile)),
  })

export default getProfiles
